/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useRouter } from 'src/routes/hooks';
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import ApiStorage from 'src/api/ApiStorage';
import { useEffect, useState } from 'react';
import APIService from 'src/api/ApiService';
import { isObjectEmpty } from 'src/api/utils';

const getProfileLink = () => {
  const pressedAccountManagement = async () => {
    try {
      const user = await ApiStorage.getSessionToken();
      const userJson = JSON.parse(user);
      if (userJson.userProfile) {
        const { authToken } = userJson;
        const { _id: userId } = userJson.userProfile;
        const redirectPage = new URLSearchParams({
          redirectPath: '/admin/user-profile',
        }).toString();
        window.open(
          `${APIService.ID}auth/redirect-page?userId=${userId}&authToken=${authToken}&${redirectPage}`
        );
      }
    } catch (e) {
      console.error(e, '<--- Error navigating to Account Management');
    }
  };
  pressedAccountManagement();
};

export default function AccountPopover() {
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const [profile, setProfile] = useState({});
  const [avatar, setAvatar] = useState('');
  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(userData);
        const { userProfile } = transformedData;
        setProfile(userProfile);
      } catch (error) {
        console.error(error);
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    const getAvatar = async () => {
      try {
        const resp = await APIService.readUserDocument({
          userId: profile._id,
          documentCriteria: APIService.documentCriteria.documentType,
          documentFilter: APIService.documentTypes.avatar,
        });
        setAvatar(resp.imagePreviewUrl);
      } catch (e) {
        console.error(e.message);
      }
    };
    !isObjectEmpty(profile) && getAvatar();
  }, [profile]);

  const handleLogout = async () => {
    try {
      // await logout();
      ApiStorage.clearSessionToken();

      popover.onClose();
      router.replace('/login');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={avatar}
          alt={profile?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {profile?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile?.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem key="Profile" onClick={getProfileLink}>
            Profile
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
