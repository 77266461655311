/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useLocales } from 'src/locales';
import ApiStorage from 'src/api/ApiStorage';
import APIService from 'src/api/ApiService';
import { isObjectEmpty } from 'src/api/utils';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { t } = useLocales();

  const [profile, setProfile] = useState({});
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    const getUserData = async () => {
      try {
        const userData = await ApiStorage.getSessionToken();
        const transformedData = JSON.parse(userData);
        const { userProfile } = transformedData;
        setProfile(userProfile);
      } catch (error) {
        console.error(error);
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    const getAvatar = async () => {
      try {
        const resp = await APIService.readUserDocument({
          userId: profile._id,
          documentCriteria: APIService.documentCriteria.documentType,
          documentFilter: APIService.documentTypes.avatar,
        });
        setAvatar(resp.imagePreviewUrl);
      } catch (e) {
        console.error(e.message);
      }
    };
    !isObjectEmpty(profile) && getAvatar();
  }, [profile]);

  const getProfileLink = () => {
    const pressedAccountManagement = async () => {
      try {
        const user = await ApiStorage.getSessionToken();
        const userJson = JSON.parse(user);
        if (userJson.userProfile) {
          const { authToken } = userJson;
          const { _id: userId } = userJson.userProfile;
          const redirectPage = new URLSearchParams({
            redirectPath: '/admin/user-profile',
          }).toString();
          window.open(
            `${APIService.ID}auth/redirect-page?userId=${userId}&authToken=${authToken}&${redirectPage}`
          );
        }
      } catch (e) {
        console.error(e, '<--- Error navigating to Account Management');
      }
    };
    pressedAccountManagement();
  };

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src={avatar} alt={profile?.name} sx={{ width: 48, height: 48 }}>
            {' '}
            {profile?.name?.charAt(0).toUpperCase()}
          </Avatar>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {profile?.name}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {profile?.email}
          </Typography>
        </Stack>

        <Button variant="contained" onClick={getProfileLink}>
          {t('profile')}
        </Button>
      </Stack>
    </Stack>
  );
}
